<template>
  <div class="not__permissers">
    <img src="/img/403.svg" alt />
  </div>
</template>

<script>
export default {
  name: 'Exception403',
  methods: {}
}
</script>

<style lang="less">
.not__permissers {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
