<template>
  <div class="not__page">
    <div class="not__page_img">
      <img src="/img/404.svg" alt="">
    </div>
    <a-button type="primary" @click="handleBack">返回上级</a-button>
  </div>
</template>

<script>
export default {
  name: 'Exception404',
  methods: {
    handleBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less">
.not__page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &_img{
    width: 450px;
    height: 450px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
